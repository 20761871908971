"use strict";
// JavaScript Document
import $ from "jquery";
import "what-input";

window.$ = require("jquery");
window.jQuery = window.$;
window.Cookies = require("js-cookie");
window.SmoothScroll = require("smooth-scroll");

require("./home.js");
require("./events.js");
require("./login.js");

require("foundation-sites");
require("../../node_modules/@fortawesome/fontawesome-free/js/all.min.js");
require("../../node_modules/vanilla-cookieconsent/dist/cookieconsent.js");

$(document).ready(function () {
  "use strict";
  $.ajaxSetup({
    cache: false,
    data: {
      csrftoken: Cookies.get(cookie_pre + "csrfcookie"),
    },
  });

  Foundation.Abide.defaults.patterns["password"] =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,12}$/;

  $(document).foundation();

  $(".btn-scroll-top").on("click", function () {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
  });

  //enable the cookie manager

  let cc = initCookieConsent();

  // run plugin with your configuration
  cc.run({
    current_lang: "en",
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: '',                      // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    gui_options: {
      consent_modal: {
        layout: "cloud", // box/cloud/bar
        position: "bottom center", // bottom/middle/top + left/right/center
        transition: "slide", // zoom/slide
        swap_buttons: false, // enable to invert buttons
      },
      settings_modal: {
        layout: "box", // box/bar
        // position: 'left',           // left/right
        transition: "slide", // zoom/slide
      },
    },
    onAccept: function (cookie) {
      // callback triggered on the first accept/reject action, and after each page load
      setAnalyticsCookies(cookie);
    },
    onChange: function (cookie, changed_categories) {
      // callback triggered on the first accept/reject action, and after each page load
      if (jQuery.inArray("analytics", changed_categories) !== -1) {
        setAnalyticsCookies(cookie);
      }
    },

    languages: {
      en: {
        consent_modal: {
          title: "We use cookies!",
          description:
            '<strong>Do you agree to the uses of cookies?</strong> This site uses cookies. By continuing to browse the site you are agreeing to our use of cookies. <a href="https://cytokinetics.com/cookie-policy/">Learn more</a> <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
          primary_btn: {
            text: "Accept all",
            role: "accept_all", // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: "Reject all",
            role: "accept_necessary", // 'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: "Cookie preferences",
          save_settings_btn: "Save settings",
          accept_all_btn: "Accept all",
          reject_all_btn: "Reject all",
          close_btn_label: "Close",
          cookie_table_headers: [
            { col1: "Name" },
            { col2: "Domain" },
            { col3: "Expiration" },
            { col4: "Description" },
          ],
          blocks: [
            {
              title: "Cookie usage 📢",
              description:
                'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="https://cytokinetics.com/cookie-policy/" class="cc-link">cookie policy</a>.',
            },
            {
              title: "Strictly necessary cookies",
              description:
                "These cookies are essential for the proper functioning of the HeartFailureEconomics.com website. Without these cookies, the website would not work properly",
              toggle: {
                value: "necessary",
                enabled: true,
                readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
              },
            },
            {
              title: "Performance and Analytics cookies",
              description:
                "These cookies allow the website to remember the choices you have made in the past",
              toggle: {
                value: "analytics", // your cookie category
                enabled: false,
                readonly: false,
              },
              cookie_table: [
                // list of all expected cookies
                {
                  col1: "_ga", // match all cookies starting with "_ga"
                  col2: "google.com",
                  col3: "2 years",
                  col4: "This cookie is a Google Analytics persistent cookie which is used to distinguish unique users",
                  is_regex: true,
                },
                {
                  col1: "_gid",
                  col2: "google.com",
                  col3: "1 day",
                  col4: "Used to send data to Google Analytics about the visitor's device and behavior",
                },
              ],
            },
            {
              title: "More information",
              description:
                'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="mailto:privacy@cytokinetics.com">contact us</a>.',
            },
          ],
        },
      },
    },
  });

  function setAnalyticsCookies(cookie) {
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      dataLayer.push(arguments);
    }

    if (jQuery.inArray("analytics", cookie.categories) == -1) {
      console.log(
        location.hostname.replace("www.", "").replace("cyto-stage.", "")
      );
      //remove google analytics cookies
      cc.eraseCookies(
        ["_gid", "_ga", "_ga_RLY7MWF2L2", "_gat"],
        "/",
        "." + location.hostname.replace("www.", "").replace("cyto-stage.", "")
      );
      gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
      });
    } else {
      //add google analytics cookies

      gtag("js", new Date());

      gtag("config", "G-RLY7MWF2L2", {
        cookie_flags: "max-age=7200;secure;samesite=none",
      });

      gtag("consent", "update", {
        ad_user_data: "granted",
        ad_personalization: "granted",
        ad_storage: "granted",
        analytics_storage: "granted",
      });

      $(function () {
        // pdf
        $('a[href*="files/download"]').on("click", function () {
          if (window.gtag) {
            let url = this.href;
            let args = {
              page_path: url,
              cookie_flags: "max-age=7200;secure;samesite=none",
            };
            if ($(this).data("ga-title")) {
              args["page_title"] = $(this).data("ga-title");
            }
            gtag("config", "G-RLY7MWF2L2", args);
          }
        });
      });
    }
  }

  //hide any messages if present
  setTimeout(function () {
    if ($("#errorMsg").length > 0) {
      $("#errorMsg").fadeOut(2000);
    }

    if ($("#regMsg").length > 0) {
      $("#regMsg").fadeOut(2000);
    }

    if ($("#successMsg").length > 0) {
      $("#successMsg").fadeOut(2000);
    }
  }, 3000);

  $(".close-reveal-modal").on("click", function (e) {
    e.preventDefault();
    $("#modal-content").html(
      '<div class="text-center" style="padding-top:50px;"><em class="fa fa-spinner fa-spin fa-5x fa-fw fa-pulse"></em><span class="sr-only">Loading...</span></div>'
    );
  });

  $(".btnSignIn").on("click", function (e) {
    e.preventDefault();
    if ($(this).hasClass("active")) {
      $(".btnSignIn").removeClass("active");
      $("#login-box").hide();
      $("#login-box-menu").hide();
    } else {
      $(".btnShare").removeClass("active");
      $("#share-box").hide();
      $("#share-box-menu").hide();
      $(".btnSignIn").addClass("active");
      $("#login-box").show();
      $("#login-box-menu").show();
    }
  });

  $(".btnShare").on("click", function (e) {
    e.preventDefault();
    if ($(this).hasClass("active")) {
      $(".btnShare").removeClass("active");
      $("#share-box").hide();
      $("#share-box-menu").hide();
    } else {
      $(".btnSignIn").removeClass("active");
      $("#login-box").hide();
      $("#login-box-menu").hide();
      $(".btnShare").addClass("active");
      $("#share-box").show();
      $("#share-box-menu").show();
    }
  });

  $(".share-link").on("click", function (e) {
    e.preventDefault();
    let name = $(this).data("name");
    $("form#frmShareModal_" + name).trigger("submit");
  });

  $(".frmShareModal").on("submit", function (e) {
    e.preventDefault();

    let formData = new FormData($(this)[0]);

    $.ajax({
      type: "POST",
      processData: false,
      contentType: false,
      url: baseurl + "ajax/shareModal",
      data: formData,
      enctype: "multipart/form-data",
    }).done(function (data) {
      $("#modal-content").html(data);
      $("#myModal").foundation("open");
    });
  });

  $(document).on("mouseup", function (e) {
    hide_menu_boxes($(".login-box"), $(".btnSignIn"), e);
    hide_menu_boxes($(".share-box"), $(".btnShare"), e);
  });
});

function hide_menu_boxes(container, button, e) {
  // if the target of the click isn't the container nor a descendant of the container
  if (!container.is(e.target) && container.has(e.target).length === 0) {
    container.hide();
    button.removeClass("active");
  }
}
