$(document).ready(function () {
  "use strict";
  $.ajaxSetup({
    cache: false,
    data: {
      csrftoken: Cookies.get(cookie_pre + "csrfcookie"),
    },
  });

  // VIDEO MODAL
  $(".frmVideoModal").on("submit", function (e) {
    e.preventDefault();

    let formData = new FormData($(this)[0]);

    //ajax call to add the client
    $.ajax({
      type: "POST",
      processData: false,
      contentType: false,
      url: baseurl + "ajax/videoModal",
      data: formData,
      enctype: "multipart/form-data",
    }).done(function (data) {
      $("#modal-content").html(data);
      $("#myModal").foundation("open");
    });
  });

  // LOGIN MODAL
  $(".loginModal").on("click", function (e) {
    e.preventDefault();
    $("form#frmLoginModalDownload").trigger("submit");
  });

  $("#frmLoginModalDownload").on("submit", function (e) {
    e.preventDefault();

    let formData = new FormData($(this)[0]);
    //ajax call to add the client
    $.ajax({
      type: "POST",
      processData: false,
      contentType: false,
      url: baseurl + "ajax/loginModal",
      data: formData,
      enctype: "multipart/form-data",
    }).done(function (data) {
      $("#modal-content").html(data);
      $("#myModal").foundation("open");
    });
  });

  // TAB SMOOTH SCROLLING
  $(".tab-link").on("click", function (e) {
    e.preventDefault();
    var target = $(this).data("target");
    var scroll = new SmoothScroll();
    var anchor = document.querySelector(target);
    var options = { speed: 800, easing: "easeOutCubic" };
    scroll.animateScroll(anchor, false, options);
  });
});
