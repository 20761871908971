$(document).ready(function () {
  "use strict";
  $.ajaxSetup({
    cache: false,
    data: {
      csrftoken: Cookies.get(cookie_pre + "csrfcookie"),
    },
  });

  $(".menu-icon").on("click", function () {
    $(this).toggleClass("active");
  });
});
